/* eslint-disable */
import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import EmployeeMeeting from '../images/backgrounds/our-services-hero-background.jpg'
import EmployeeMeetingMobile from '../images/backgrounds/our-services-hero-background-mobile.jpg'

import HandsWithPhone from '../images/people/hands-with-phone.png'
import PerformanceMarketers from '../images/people/performance-marketers.png'
import headphone from '../images/illustrations/headset-black.svg'
import graph from '../images/illustrations/line-graph-black.svg'
import heart from '../images/illustrations/heart-black.svg'

import HubspotForm from '../components/Form/HubspotForm'

import useWindowSize from '@leshen/ui/src/hooks/useWindowSize'

import {
  Billboard,
  SplitContent,
  VariableContent,
  List,
  ListItem,
  Column,
  Columns,
  Typography,
  LinkButton,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import header from '../components/Header'
import footer from '../components/Footer'
import Footer from '../components/Footer'
import footerData from '../data/footerData'
import headerData from '../data/headerData'

const Home = () => {
  const windowSize = useWindowSize()
  const isMobile = windowSize.width < 940
  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(),
        seo: {
          title: 'Our Services | Clearlink',
          description:
            'Expertise at every stage of the funnel including digital Marketing, call center services, social media management, media, advertising and agency of record.',
          canonical: 'https://www.clearlink.com/',
          robots: 'follow,index',
        },
        path: '/our-services',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://www.clearlink.com',
          siteName: 'Clearlink',
          alternateName: 'Clearlink',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate: 'Our Services | Clearlink',
    defaultRobots: 'follow,index',
    header: header(),
    footer: footer(),
    main: (
      <>
        <Billboard
          backgroundColor="dark"
          alignImageToBottom
          color="white"
          className="content-transparent-desktop mobile-image-full"
          variant="full"
          image={
            <img
              src={EmployeeMeeting}
              alt="employees working in an office environment"
            />
          }
          mobileImage={
            <img
              src={EmployeeMeetingMobile}
              alt="employees working in an office environment"
            />
          }
          mainContent={
            <div
              data-sal="slide-right"
              data-sal-delay="100"
              data-sal-duration="500"
              data-sal-easing="ease"
              style={{ marginBottom: '20px' }}
            >
              <Typography variant="h1">
                A catalog of services designed for you
                <span className="blue-punctuation-large">.</span>
              </Typography>
              <Typography variant="h4" style={{ marginBottom: '26px' }}>
                Your business is unique and demands more than a generic
                approach. Our full-funnel marketing suite allows you to choose
                the services that best suit your specific needs.
              </Typography>
              <LinkButton
                variant="hero"
                to="#hubpsot-form-section"
                color="primary"
              >
                Start the conversation{' '}
              </LinkButton>
            </div>
          }
        />

        <SplitContent
          backgroundColor="white"
          alignImageToBottom
          className="image-bottom-fix"
          mobileAlignImageToBottom
          image={
            <img src={PerformanceMarketers} alt="marketers walk together" />
          }
          color="black"
          mainContent={
            <>
              <div
                data-sal="slide-left"
                data-sal-delay="100"
                data-sal-duration="500"
                data-sal-easing="ease"
              >
                <Typography variant="h1">
                  Performance Marketing
                  <span className="blue-punctuation-large">.</span>
                </Typography>
                <Typography variant="body">
                  Take advantage of our unique business model that prioritizes
                  results. We’ll find the right customers and accelerate lasting
                  business for your brands by utilizing:
                </Typography>
                <List>
                  <ListItem>Sustainable organic growth</ListItem>
                  <ListItem>Strategic and efficient paid media</ListItem>
                  <ListItem>Automatic and programmatic marketing</ListItem>
                  <ListItem>Our own dedicated sales team</ListItem>
                </List>
                <LinkButton
                  to="/performance-marketing"
                  style={{ marginTop: '10px' }}
                  color="primary"
                >
                  Learn More
                </LinkButton>
              </div>
            </>
          }
        />

        <SplitContent
          backgroundColor="primary"
          alignImageToBottom
          mobileAlignImageToBottom
          className="image-bottom-fix"
          image={<img src={HandsWithPhone} alt="hands holding a phone" />}
          mainContent={
            <>
              <div
                data-sal="slide-right"
                data-sal-delay="100"
                data-sal-duration="500"
                data-sal-easing="ease"
              >
                <Typography variant="h1">
                  Connect with our Internet and Lifestyle Brands
                </Typography>
                <Typography className="mb-32">
                  Drive meaningful decision-making from the very top of the
                  funnel to the final sale with our trusted properties. We
                  cultivate informed leads that are eager to make a purchase.
                </Typography>
                <LinkButton to="/our-properties" color="dark">
                  See our brands
                </LinkButton>
              </div>
            </>
          }
        />

        <VariableContent
          backgroundColor="black"
          alignMainContent="center"
          mainContent={
            <>
              <div
                data-sal="slide-up"
                data-sal-duration="500"
                data-sal-easing="ease"
              >
                <Typography variant="h2">
                  Money, meet our mouths
                  <span className="white-punctuation-large">.</span>
                </Typography>
                <Typography variant="body">
                  We cultivate customers that last—and are more than just a
                  number on a page. Last year our marketing efforts generated
                  almost 30 million leads resulting in over 300k individual
                  sales for our partners.
                </Typography>
              </div>
            </>
          }
        >
          <div
            data-sal="slide-up"
            data-sal-duration="500"
            data-sal-easing="ease"
          >
            <Columns leftAligned={true}>
              <Column backgroundColor="white">
                <img
                  src={headphone}
                  alt="headphone icon"
                  className="card-icon"
                />
                <Typography variant="h4">Sales Experts</Typography>
                <Typography variant="body">
                  Our award-winning sales center sets the standard for customer
                  acquisition and retention. We train dedicated sales agents to
                  help them guide customers through the final step in their
                  purchasing journey.
                </Typography>
                <a href="/sales-solutions" className="simple-link">
                  <span>See what sets us apart</span>
                </a>
              </Column>
              <Column backgroundColor="white">
                <img src={heart} alt="heart icon" className="card-icon" />
                <Typography variant="h4">SocialX</Typography>
                <Typography variant="body">
                  Our robust data and technology suite means you’ll have access
                  to in-depth customer information, real-time campaign results,
                  and proprietary sales statistics to take your business to the
                  next level.
                </Typography>
                <a href="/socialx/services" className="simple-link">
                  <span>See the details</span>
                </a>
              </Column>
              <Column backgroundColor="white">
                <img src={graph} alt="graph icon" className="card-icon" />
                <Typography variant="h4">Agency</Typography>
                <Typography variant="body">
                  Our marketing experts have decades of experience in the
                  industry. We’ve cultivated partnerships with some of the
                  biggest companies in a variety of verticals and are proud of
                  what we’ve accomplished.
                </Typography>
                <a href="/partner-with-us" className="simple-link">
                  <span>Start the conversation</span>
                </a>
              </Column>
            </Columns>
          </div>
        </VariableContent>

        <HubspotForm
          heading="Partner with us"
          punctuation="."
          subheading="Learn how our full-funnel approach to marketing can focus your efforts and get results. When you partner with Clearlink, you can expect passionate and knowledgeable marketers who treat your success as our own. Let us show you what a true partnership is like."
        />
      </>
    ),
    disableBreadcrumbs: true,
  }

  return <Layout {...layoutProps} />
}
export default Home
